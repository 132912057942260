body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a[href^="http"]::after,
a[href^="https://"]::after {
    content: "";
    width: 11px;
    height: 11px;
    margin-left: 4px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
}

a[href=""] {
    color: red;
}

.App {
    margin: 0;
    height: 100vh;
    display: flex;
    flex-flow: column;
}

.pseudolink {
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

.pseudolink.inactive {
    color: gray;
}

.pseudolink.tiny {
    font-size: small;
}

.topnav {
    margin-left: 20px;
    margin-right: 20px;
    justify-content: space-between;
    align-items: center;
    flex: 0 1 auto;

    display: flex;
}

.topnav span a {
    display: inline-block;
    padding-right: 5px;
}

.nav-item {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px 15px;
    text-decoration: none;
    background-color: rgb(233, 233, 233);
    border-radius: 8px;
}

.nav-item.primary {
    background-color: blanchedalmond;
}

div.imgscrollmenu {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

div.imgscrollmenu span {
    display: inline-block;
    text-align: center;
    padding: 14px;
    text-decoration: none;
}

div.imgscrollmenu img {
    height: 400px;
}

@media (max-width:800px) {
    div.imgscrollmenu span {
        display: block;
    }

    div.imgscrollmenu img {
        height: auto;
        width: 100%;
    }
}

.cool-container {
    text-align: left;
    margin-top: 20px;
    margin-left: 80px;
}


#showcase {
    flex: 1 1 auto;
    display: flex;
    height: 100%;
}

#monaco-editor-embed {
    width: 55%;
    text-align: left;

    border: 1px solid rgb(210, 210, 210);
}

#right-panel {
    text-align: left;
    border: 1px solid rgb(210, 210, 210);

    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
}

#sandbox-controls {
    flex: 0 1 auto;
}

#sandbox-runtime {
    flex: 1 1 auto;
    overflow: scroll;
}

.project-container {
    text-align: left;
    flex: 1 1 auto;

    display: flex;
}

.project-navlist {
    width: 250px;
    flex: 0 0 auto;
}

.project-navlist div {
    font-size: small;
    padding-left: 10px;
    padding-right: 10px;
}

.project-navlist span {
    padding-left: 10px;
}

.project-description {
    padding-left: 25px;
    padding-right: 25px;
    background-color: bisque;
    flex: 1 1 auto;
}

.project-description span {
    padding-right: 5px;
}

.project-description h2 {
    display: inline-block;
    padding-right: 10px;
}

.project-description .tagline {
    font-size: small;
    color: rgb(55, 55, 55);
}



.project-description .emph a {
    font-weight: 800;
    color: rgb(255, 91, 91)
}

.project-description .emph:visited a {
    font-weight: 800;
    color: rgb(207, 73, 73)
}


.main-links a {
    display: block;
}

.main-links.large a {
    font-size: large;
}